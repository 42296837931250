<template>
  <component
    :is="h1 ? 'h1' : h2 ? 'h2' : h3 ? 'h3' : h4 ? 'h4' : h5 ? 'h5' : h6 ? 'h6' : 'div'"
    :class="[{ 'text-3xl md:text-5xl font-bold': dh1 }, { 'text-2xl md:text-4xl font-semibold': dh2 }, { 'text-xl md:text-2xl font-semibold': dh3 }, { 'text-lg font-semibold': dh4 }, { 'text-base font-semibold': dh5 }, { 'text-base font-semibold': dh6 } ]"
  >
    <slot />
  </component>
</template>

<script setup lang="ts">
const props = defineProps({
  type: {
    type: String,
    default: 'div'
  },
  design: {
    type: String,
    default: 'h1'
  }
})
const h1 = computed(() => props.type === 'h1')
const h2 = computed(() => props.type === 'h2')
const h3 = computed(() => props.type === 'h3')
const h4 = computed(() => props.type === 'h4')
const h5 = computed(() => props.type === 'h5')
const h6 = computed(() => props.type === 'h6')

const dh1 = computed(() => props.design === 'h1')
const dh2 = computed(() => props.design === 'h2')
const dh3 = computed(() => props.design === 'h3')
const dh4 = computed(() => props.design === 'h4')
const dh5 = computed(() => props.design === 'h5')
const dh6 = computed(() => props.design === 'h6')

</script>
